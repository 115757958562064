<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-show="unReadNotifications.length>0"
        :badge="unReadNotifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
          v-text="unReadNotifications.length <= 1 ?unReadNotifications.length +' non lue' : unReadNotifications.length +' non lues'"
        />
      </div>

    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
    >
      <b-link
        v-for="item in unReadNotifications"
        :key="item.id"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="item.data.type"
            >
              <feather-icon icon="CheckIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span
              class="font-weight-bolder"
              v-text="item.data.title"
            />

          </p>
          <small
            class="notification-text"
            v-text="item.data.subtitle"
          />
        </b-media>
      </b-link>
      <!-- System Notifications -->

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Marquées toutes lues</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import _ from 'lodash'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import useAuthenticate from '@/services/authentification/authService'
import useAuthUser from '@/services/authentification/userService'
import {
  onMounted,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import Pusher from 'pusher-js'

import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },

  setup() {
    const { logout } = useAuthenticate()
    const {
      getAuthUser, user, getUnreadNotifications, unReadNotifications,
    } = useAuthUser()

    const { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } = $themeConfig.app
    // const offre = ref([])
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
    })

    const channel = pusher.subscribe('offre-notification-channel')
    channel.bind('offre-notification-event', async () => {
      await getUnreadNotifications()
    })
    const channelDevis = pusher.subscribe('commentaire-devis-channel')
    channelDevis.bind('commentaire-devis-event', async () => {
      await getUnreadNotifications()
    })
    onMounted(async () => {
      await getAuthUser()
      await getUnreadNotifications()
    })
    const perfectScrollbarSettings = {
      maxScrollbarLength: 100,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
      unReadNotifications,
      logout,
      user,
    }
  },
}

</script>
